.tour__img img {
    width: 100%;
    border-radius: 5px 5px 0 0;

}
.all__card {
    display: flex;
}
.card {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.tour__img {
    position: relative;
}

.tour__img img {
    width: 100%;
    height: 200px;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    object-position: center;
}

.tour__img span {
    position: absolute;
    color: #fff;
    border-radius: 3px;
}

.tour__img span.top {
    background: var(--primary-color);
    padding: 0.3rem 0.5rem;
    top: 0;
    left: 0;
}

.tour__img span.bottom {
    position: absolute;
    text-align: center;
    padding-bottom: 2px;
    width: 90px;
    border-radius: 5px;
    bottom: 0;
    right: 0;
    margin: 5px;
    font-size: small;
    background: #30302f; /* Translucent black background */
    border: 2px solid orange;
    color: white;
    text-transform: uppercase; /* Convert text to lowercase */

}



.card__top span i {
    color: var(--secondary-color);
}

.tour__location {
    color: var(--heading-color);
    font-weight: 500;
    font-size: 0.9rem;
}

.tour__rating i,
.tour__location i {
    font-size: 1rem;
}

.tour__title {
    margin-top: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
}

.tour__title a {
    text-decoration:  none;
    color: var(--heading-color);
    transition: 0.3s;
}

.tour__title a:hover {
    color: var(--secondary-color);
}

.card__bottom h5 {
    color: var(--secondary-color);
    font-size: 1.3rem;
    font-weight: 700;
}

.booking__btn {
    background: var(--secondary-color) !important;
    cursor: pointer;
    padding: 0.4rem 0.3rem !important;
}

.booking__btn a {
    text-decoration: none;
    font-size: 0.9rem;
    color: #fff;
}

.booking__btn a:hover {
    color: #fff;
}

.card__bottom h5 span {
    font-weight: 500;
    color: var(--text-color);
    font-size: 0.8rem;
}
.tour__daydetails h5 span {
    font-weight: 500;
    color: var(--text-color);
    font-size: 0.8rem;
}