/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
/*#ff7e01*/
/*#faa935*/
:root {
  --primary-color: #ff7e01;
  --secondary-color: #faa935;
  --heading-color: #0b2727;
  --text-color: #6e7074;
  --font-name: "Montserrat", sans-serif;
  --subtitle-font-name: "Poppins", cursive;
}

* {
  margin-top: 0;
  padding: 0;
  box-sizing: border-box;
}

section {
  padding: 30px 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

a {
  text-decoration: none;
  color: unset;
}
ul {
  list-style: none;
}

.primary__btn {
  background: var(--secondary-color) !important;
  border: none !important;
  border-radius: 50px !important;
  padding: 0.4rem 1.5rem !important;
}

.primary__btn a {
  font-size: 1.1rem;
  text-decoration: none;
  color: #fff;
}
.primary__btn a:hover {
  color: inherit;
}
.secondary__btn {
  background: transparent !important;
  color: #0b2727 !important;
  border: none !important;
  font-weight: 500 !important;
}

.secondary__btn a {
  text-decoration: none;
  font-size: 1.1rem;
  color: var(--heading-color);
}

.secondary__btn a:hover {
  color: inherit;
}

.section__subtitle {
  background: var(--secondary-color);
  font-family: var(--subtitle-font-name);
  width: max-content;
  padding: 5px 1.5rem;
  padding-right: 1.5rem;

  border-radius: 50px;
  font-weight: 400;
  font-size: 1.3rem;
  color: var(--heading-color);
}

