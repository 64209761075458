.container {
	align-items: flex-start;
	box-shadow: var(--box-shadow);
	border-radius: 4px;
}

.heading {
	margin: 0;
	font-size: 16px;
	margin: 10px;
	text-align: center;
}

.genre_container {
	flex-direction: row;
	flex-wrap: wrap;

}

.genre {
	min-width: 90px;
	display: flex;
	align-items: center;
	margin: 2px 0;

}

.genre_label {
	margin: 0;
	margin-left: 5px;

}
