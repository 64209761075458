.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.logo img {
    width: 30%;
}

.menu {
    margin-bottom: 0;
}

.nav__item a {
    text-decoration: none;
    color: var(--heading-color);
    font-weight: 500;
    font-size: 1rem;
}

.nav__item a:hover {
    color: inherit;
}

.nav__item a.active__link {
    color: var(--secondary-color);
}

.mobile__menu {
    font-size: 1.3rem;
    color: var(--heading-color);
    display: none;
}

.sticky__header {
    width: 100px;
    height: 80px;
    line-height: 80px;
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
}