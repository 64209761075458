.hero__subtitle img {
    width: 2.3rem;
    height: 2.3rem;
}

.hero__content h1{
    font-size: 3.2rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: var(--heading-color);
}

.hero__content h1 .hightlight {
    color: var( --secondary-color);
}

.hero__content p{
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 2rem;
}

.hero__img-box img, .hero__img-box video {
    width: 100%;
    height: 350px;
    border-radius: 20px;
    border: 1px solid var(--secondary-color);
    object-fit: cover;
}

.hero__content {
    padding-top: 3.5rem;
}

.hero__img-box {
    padding-top: 2rem;
}

.services__subtitle {
    font-size: 2.2rem;
    font-weight: 500;
    color: #ee6e6e !important;
    font-family: var(--subtitle-font-name) !important;
}

.services__tile {
    font-size: 2.5rem;
    font-weight: 500;
}

.featured__tour-title {
    font-size: 2.3rem;
    font-weight: 500;
}

.experience__content h2{
    font-size: 2.5rem;
    margin-top: 1rem;
    font-weight: 500;
}

.experience__content p {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-top: 1rem;
}

.counter__wrapper {
    margin-top: 2.5rem;
}

.counter__box span {
    width: 50px;
    height: 50px;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery__title{
    font-size: 2.3rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.testimonial__title {
    font-size: 2.3rem;
    margin-top: 1rem;
}
.circle {
    display: inline-block;
    width: 40px; /* Đường kính của hình tròn */
    height: 40px; /* Đường kính của hình tròn */
    border-radius: 50%; /* Tạo hình tròn */
    background-color: orange; /* Màu nền của hình tròn */
    text-align: center; /* Căn giữa nội dung trong hình tròn */
    line-height: 40px; /* Căn giữa nội dung trong hình tròn */
    color: white; /* Màu chữ */
    font-weight: bold; /* Độ đậm của chữ */
}
