
.core-feature {
    margin-top: 110px;
    margin-bottom: 110px;
}

.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-size: 80px;
    line-height: 1.125;
    letter-spacing: -1px;
    font-family:'Times New Roman', Times, serif;
    font-weight: 400;
}

.features-loop .feature-box {
    padding: 50px 40px;
    font-size: 14px;
    background-color: white;
    border: 2px solid #f8f8f8;
    position: relative;
    margin-top: 20px;
}

.feature-box h1 {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.description p {
    color: var(--text-color)
}

.text-block.with-pattern {
    position: relative;
    z-index: 1;
}

.bg-black {
    background-color: #303b66 !important;
}

.text-block.with-pattern .pattern-wrap {
    position: absolute;
    background: #927763;
    right: 0;
    height: 100%;
    z-index: -1;
    width: 30%;
    top: 0;
}

.hero__img-box {
    width: 100%;
    height: 100%;
}

.text-block.bg-black p, .text-block.bg-black .section-title h2 {
    color: #fff;
}

.text-block.bg-black .section-title .title-tag {
    color: #927763;
}
.introduction {
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-section .about-features-boxes .single-feature-box {
    background-color: #fff;
    padding: 50px 30px;
    box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
    text-align: center;
    font-size: 14px;
}

.about-section .about-features-boxes .single-feature-box.only-bg {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 315px;
    padding: 0;
    background-size: cover;
    background-position: center;
}

